<template>
  <div class="input-wrapper">
    <div
      class="input-label inline-flex flex-items-center"
      :class="{
        'input-label_with-border': withBorder,
      }"
    >
      <div ref="labelRef" class="input-label__text">{{ label }}</div>

      <div
        class="slot"
        :style="{width: `calc(100% - ${labelRef?.offsetWidth}px)`}"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  withDefaults(
    defineProps<{
      label: string;
      withBorder: boolean;
    }>(),
    {
      label: '',
      withBorder: false,
    },
  );

  const labelRef = ref<HTMLDialogElement>();
</script>

<style scoped lang="scss">
  @import 'themes/mytheme/variables';

  $bg-color: #f2f2f2;

  .input-label {
    display: flex;
    justify-content: space-between;
    background: $bg-color;
    border-radius: $inputBorderRadius;

    &__text {
      padding: 1.625rem 1rem 1.625rem 1.5rem;
      color: rgba($secondaryColor, 51%);
      text-wrap: nowrap;
      font-size: 14px;
    }

    &_with-border {
      outline: 3px solid transparent;
      border: 3px solid $bg-color;
      margin: -3px 0;
    }

    .slot {
      width: 100%;

      > * {
        width: 100%;
      }
    }
  }
</style>
