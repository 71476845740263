import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import nuxt_plugin_RZGiRwB5P6 from "/usr/src/nuxt-app/node_modules/nuxt-open-fetch/dist/runtime/nuxt-plugin.mjs";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/usr/src/nuxt-app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/src/nuxt-app/.nuxt/sentry-client-config.mjs";
import unocss_MzCDxu9LMj from "/usr/src/nuxt-app/.nuxt/unocss.mjs";
import primevue_plugin_egKpok8Auk from "/usr/src/nuxt-app/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/usr/src/nuxt-app/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import click_outside_D2NiLGp7dJ from "/usr/src/nuxt-app/plugins/click-outside.ts";
import prime_ng_tooltip_ZfsOPA6xRb from "/usr/src/nuxt-app/plugins/prime-ng-tooltip.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  nuxt_plugin_RZGiRwB5P6,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  unocss_MzCDxu9LMj,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  chunk_reload_client_UciE0i6zes,
  click_outside_D2NiLGp7dJ,
  prime_ng_tooltip_ZfsOPA6xRb
]